var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("Subscribers")]),_c('p',[_vm._v("This view contains a list of subscribers - Clients.")]),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('fancy-button',{attrs:{"text":"Refresh","icon":"mdi-refresh"},on:{"click":function($event){return _vm.reload()}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-expand-transition',[(_vm.error)?_c('v-alert',{attrs:{"type":"error","text":""}},[_vm._v(_vm._s(_vm.error))]):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.subscribers,"headers":_vm.headers},on:{"click:row":function (item) { return _vm.$router.push(("/subscribers/details/" + (item._id))); }},scopedSlots:_vm._u([{key:"item.picture",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',[_c('v-img',{attrs:{"src":item.picture}})],1)]}},{key:"item.lastLogin",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.lastLogin)))])]}},{key:"item.uses",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.uses || 0))])]}},{key:"item.company",fn:function(ref){
var item = ref.item;
return [(item.company)?_c('router-link',{attrs:{"to":("/companies/" + (item.company._id))}},[_vm._v(_vm._s(item.company.name))]):_c('span',[_vm._v("-")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }