



























import { Vue, Component } from "vue-property-decorator";
import Subscribers from "@/store/subscribers";
import FancyButton from "@/components/FancyButton.vue";

@Component({
  components: {
    FancyButton
  }
})
export default class extends Vue {
  headers = [
    { text: "Picture", value: "picture", width: "10px" },
    { text: "Name", value: "name" },
    { text: "Email", value: "email" },
    { text: "Sign Up City", value: "signUpCity" },
    { text: "Active Reusables", value: "activeReusables" },
    { text: "Total Reusables", value: "uses" },
    { text: "Company", value: "company" },
    { text: "Last Login", value: "lastLogin" }
  ];

  get subscribers() {
    return Subscribers.subscribers;
  }

  get loading() {
    return Subscribers.loading;
  }

  get error() {
    return Subscribers.error;
  }

  async mounted() {
    this.refresh();
  }

  async refresh() {
    await Subscribers.fetchAll();
  }

  reload() {
    this.refresh();
    this.$forceUpdate();
  }
}
